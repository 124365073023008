import React, { useState, useRef, useEffect } from "react";
import "./forgotPassword.styles.scss";
import ForgotPasswordIllustration from "../../assets/Img/ForgotPassword/forgot-password.svg";
import { ForgotPasswordEmailComponent } from "./ForgotPasswordComponents/ForgotPasswordEmail.component";
import ForgotPasswordUserName from "./ForgotPasswordComponents/ForgotPasswordUserName.component";
import AfterReset from "./ForgotPasswordComponents/AfterReset.component";
import CompanyLogo from "../../assets/AlpacaLogo/AlpacaHorizontalBlack.svg";
import { Result, Toast } from "@alpaca/framework";
import NewPassword from "./ForgotPasswordComponents/NewPassword.component";
import {
  UpdatePasswordService,
  RequestResetService,
  ValidateTokenService,
} from "../../service/resetPassword/resetPassword";
import { useLocation, useNavigate } from "react-router-dom";
import { getWideLogoService } from "../../service/VisualChanges/visualChanges";
function ForgotPassword(props) {
  const [useUserName, setUseUserName] = useState(false);
  const [afterReset, setAfterReset] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [wideLogo, setWideLogo] = useState(CompanyLogo);
  const toastRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.pathname.split("/forgot-password/")[1];

  useEffect(() => {
    if (token) {
      ValidateTokenService(token)
        .then((result) => {
          setValidToken(result);
        })
        .catch((error) => {
          setValidToken(false);
          toastRef.current?.open({
            title: error.message,
            description:
              "Your password reset is expire or invalid, please request again!",
            duration: 5,
            status: "error",
          });
        });
    }
  }, [token]);

  useEffect(() => {
    if (props.loginLocation) {
      getWideLogoService(props.loginLocation)
        .then((result) => {
          if (result) {
            setWideLogo(result);
          }
        })
        .catch((error) => {
          toastRef.current?.open({
            title: "Get Company Logo failed",
            description: error.message,
            duration: 3,
            status: "error",
          });
        });
    }
  }, [props.loginLocation]);

  const requestReset = (value) => {
    if (useUserName) {
      if (value.profile_name === "") {
        toastRef.current.open({
          title: "Empty Username",
          description: "Please enter the username",
          duration: 3,
          status: "error",
        });
        return;
      }
      if (value.company_name === "") {
        toastRef.current.open({
          title: "Empty Company",
          description: "Please enter the company",
          duration: 3,
          status: "error",
        });
        return;
      }
      //application allow for duplicate emails now
    } else {
      if (value.email_address === "") {
        toastRef.current.open({
          title: "Empty Email",
          description: "Please enter the email",
          duration: 3,
          status: "error",
        });
        return;
      }
    }

    //handle reset
    RequestResetService({
      ...value,
      company_name: props.loginLocation || null,
    })
      .then((result) => {
        setAfterReset(true);
      })
      .catch((error) => {
        const message = error.message;
        let description = "Please Try again later or Contact Help Desk";
        switch (message) {
          case "Account not valid":
            description =
              "Your username/email is not recognized. Please try again!";

            break;
          case "Account Disabled":
            description =
              "Your account is disabled and cannot request password reset";
            break;
          case "No Email":
            description =
              "Your account did not set an Email and cannot request password reset";
            break;
          case "Server Error":
            description =
              "Unable to send the reset Email, please contact the help desk for help";
            break;
          case "Multiple Accounts":
            setUseUserName(true);
            description =
              "There are multiple accounts linked with your email, please use the username to reset";
            break;
          default:
            break;
        }

        toastRef.current.open({
          title: message,
          description: description,
          duration: 3,
          status: "error",
        });
      });
  };

  const resetPassword = (newPassword) => {
    UpdatePasswordService(newPassword, token)
      .then((result) => {
        toastRef.current.open({
          title: result.title,
          description: result.description,
          duration: 3,
          status: "success",
        });
        setTimeout(() => (window.location.pathname = "/portal"), 2000);
      })
      .catch((error) => {
        toastRef.current.open({
          title: "Reset password fail",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };

  const renderContent = () => {
    if (!validToken) {
      if (afterReset) {
        return <AfterReset />;
      } else {
        return (
          <section className="forgotPasswordBackground">
            <img
              src={wideLogo}
              className="login-logo"
              alt={"login logo"}
              onError={(e) => {
                e.target.src = CompanyLogo;
              }}
            />
            <div className="forgotPasswordContainer">
              <div className="forgotPasswordContainer_columnLeft">
                <h2 className="medium headline">Forgot Password</h2>
                <p className="body-text">
                  We’ll email you a link to reset your password
                </p>
                {
                  /*<ForgotPasswordUserName
                    onBtnClick={requestReset}
                  /> */
                  useUserName ? (
                    <ForgotPasswordUserName
                      onClick={() => setUseUserName(false)}
                      onBtnClick={requestReset}
                    />
                  ) : (
                    <ForgotPasswordEmailComponent
                      onClick={() => setUseUserName(true)}
                      onBtnClick={requestReset}
                    />
                  )
                }
              </div>
              {/* Illustration */}
              <div className="forgotPasswordContainer_columnRight">
                <img src={ForgotPasswordIllustration} alt="Forgot Password" />
              </div>
            </div>
          </section>
        );
      }
    } else {
      return <NewPassword onBtnClick={resetPassword} />;
    }
  };

  return (
    <>
      <Toast ref={toastRef} />
      {renderContent()}
    </>
  );
}

export default ForgotPassword;
