import React from "react";
import "./moduleheader.styles.css";
import ModulesHeaderImg from "./../../assets/Img/Modules/modules-header-img.svg";
import { UserInfoSelector } from "../../store/reduxSelector";

export function ModulesHeaderComponent(props) {
  const user = UserInfoSelector();

  return (
    <>
      <section className="modules-header">
        <h1 className="modules-welcome-headline">
          Welcome Back, {user.firstName || ""}
        </h1>
        <img
          src={ModulesHeaderImg}
          alt="dashboard modules graphic"
          className="modules-header-img"
        />
      </section>
    </>
  );
}

export default ModulesHeaderComponent;
