import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Form, FocusInputs } from "@alpaca/framework";
import classNames from "classnames";

import attemptLogin from "../../../service/authentication/attemptLogin";
import { login } from "../../../store/reducer/userInfoReducer";
import { setVisualSetting } from "../../../store/reducer/visualReducer";
import "./login-form.scss";

function LoginForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Enter
      form.submit();
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleLogin}
      className="login-form"
      //onChange={() => setServerError()}
      onKeyUp={handleKeyUp}
    >
      <div className={classNames("validation", { show: serverError })}>
        {serverError}
      </div>
      <Form.Item name="username">
        <FocusInputs.Input
          title="Username"
          disabled={isLoading}
          onChange={(e) => setServerError(false)}
        />
      </Form.Item>
      <Form.Item name="password">
        <FocusInputs.Password
          title="Password"
          visibilityToggle={false}
          disabled={isLoading}
          onChange={(e) => setServerError(false)}
        />
      </Form.Item>
      <Link to="/forgot-password" className="forget-password">
        Forget Password ?
      </Link>
      <div className="btn-container">
        <Button
          loading={isLoading}
          onClick={() => form.submit()}
          className="btn main"
          style={{ backgroundColor: "#FED208", borderColor: "#FED208" }}
        >
          Log In
        </Button>
        <Link to="/register">
          <button className="btn secondary" type="button">
            Sign Up
          </button>
        </Link>
      </div>
    </Form>
  );

  // Methods
  async function handleLogin(inputs) {
    try {
      form.resetFields(["password"]);
      setIsLoading(true);
      const attempt = await attemptLogin({
        ...inputs,
        company_name: props.selectedCompany,
      });

      const { visualSetting, ...userInfo } = attempt.info;
      await dispatch(login(userInfo));
      await dispatch(setVisualSetting(visualSetting));
      if (document.referrer) {
        // Whats this previous page stuff? its because referrer is "https://url.com/" but origin is "https://url.com". not pretty but it'll do
        let previousPage = JSON.parse(JSON.stringify(document.referrer));
        if (previousPage[previousPage.length - 1] === "/") {
          previousPage = previousPage.slice(0, -1);
        }
        if (previousPage !== document.location.origin) {
          // If there is a previous page, go back
          window.location.href = document.referrer;
          //THANKS REDUX!
          return;
        }
      }
      // If no previous page, navigate to "/dashboard"
      // not part of the else to avoid duplicated code.
      navigate("/dashboard");
    } catch (error) {
      setServerError(error.message);
    } finally {
      setIsLoading(false);
    }
  }
}

export default LoginForm;
