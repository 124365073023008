import "./editDevice.styles.css";
import React, { useState, useEffect } from "react";
import { Checkbox } from "@alpaca/framework";
import { getAllProfile } from "../../../../service/profile/profile";
import "./useList.styles.scss";
import { NoDataAssignedPlaceholder } from "../NewDevice/NoDataAssignedPlaceholder";
import { ReactComponent as NoUsersFound } from "../../../../assets/graphics/NoUsersFound.svg";

function UserList(props) {
  const [profiles, setProfiles] = useState(null);

  useEffect(() => {
    getAllProfile(props.userInfo?.company_name)
      .then((data) => setProfiles(data))
      .catch((error) => {
        if (props.openToast) {
          props.openToast({
            title: "Get Profile List fail!",
            description: error.message,
            duration: 5,
            status: "error",
          });
        }
      });
  }, []);

  const isChecked = (profileName) => {
    return (
      props.device?.profile?.some(
        (profile) => profile.profile_name === profileName
      ) || false
    );
  };

  const handleCheckBoxChange = (index) => {
    const profileArray = JSON.parse(JSON.stringify(props.device.profile));
    let findIndex = profileArray.findIndex(
      (profile) => profile.profile_name === profiles[index].profile_name
    );
    if (findIndex === -1) {
      profileArray.push(profiles[index]);
    } else {
      profileArray.splice(findIndex, 1);
    }

    props.onData({ ...props.device, profile: profileArray });
  };

  return (
    <div className="edit-device-list">
      {/* {Array.isArray(profiles) === true && (
        <h4 className="addUserHeadline">Please select a user below</h4>
      )} */}
      {Array.isArray(profiles) === true ? (
        profiles.map((profile, index) => {
          if (profile.profile_status === "Invite") return <></>;

          return (
            //the reason this is a label is to access the htmlFor attribute. Do not change. RD
            <label
              className="userListItem"
              htmlFor={`checkbox-${profile.profile_name}`}
            >
              <Checkbox
                id={`checkbox-${profile.profile_name}`}
                defaultChecked={isChecked(profile.profile_name)}
                onChange={() => handleCheckBoxChange(index)}
                value={profile.profile_name}
              />

              <div className="userNameContainer">
                <span className="userName_FullName">{`${profile.first_name} ${profile.last_name}`}</span>
                <span className="userName_ProfileName">
                  {profile.email_address}
                </span>
              </div>
            </label>
          );
        })
      ) : (
        <NoDataAssignedPlaceholder
          text="Oops! No users could be found"
          graphic={<NoUsersFound style={{ width: "200px", margin: 0 }} />}
          callToAction={false}
        />
      )}
    </div>
  );
}

export default UserList;

{
  /* <div>
{console.log(profiles)}
<div className="edit-device-list">
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>Antonio</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>devuser</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>Evanm</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>gcairns</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>jeffjolliffe</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>lphasiri</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>MRichardson</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>raman</h3>
	</div>
</div>
</div> */
}
