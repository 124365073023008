import React from "react";
import { Button } from "@alpaca/framework";
import "./usersAssigned.styles.scss";

export function UsersAssigned({ users = [], addUser }) {
  //This is used to add more colour and POP to the page - RD
  const colours = [
    "#6cb476",
    "#862461",
    "#df922f",
    "#078aa8",
    "#006837",
    "#6cb476",
    "#ed1e79",
    "#29aae2",
    "#f1ba1b",
  ];

  function determineAvatarColor(first_name, last_name, colourArray = colours) {
    // v1 of some messy and unpolished pseudo random color selector
    const vowels = {
      a: 7,
      e: 1,
      i: 2,
      o: 3,
      u: 4,
      y: 6,
    };
    let name = `${first_name} ${last_name}`;
    let score = name.length;
    for (const character in name) {
      const letter = name[character];
      score += vowels[letter.toLowerCase()] || 0;
    }

    return colourArray[score % colourArray.length];
  }

  return (
    <div className="userOuterContainer">
      <div className="userAssignedContainer">
        {users.map((data, index) => {
          return (
            <div key={index} className="userAssignedItem">
              <div
                className="avatarContainer"
                style={{
                  backgroundColor: determineAvatarColor(
                    data.first_name,
                    data.last_name
                  ),
                }}
              >
                <span className="avatarInitals">
                  {data?.first_name?.slice(0, 1).toUpperCase()}
                  {data?.last_name?.slice(0, 1).toUpperCase()}
                </span>
              </div>
              <div className="usersAssignedDetails">
                <p className="userName_FullName">
                  {data?.first_name} {data?.last_name}
                </p>
                <p className="userName_ProfileName">{data?.email_address}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="addUserBtnContainer">
        <Button type="primary" onClick={addUser} size="large">
          Add Additional Users
        </Button>
      </div>
    </div>
  );
}
