import React from "react";
import "./noDataAssigned.styles.scss";
import { Button } from "@alpaca/framework";

export function NoDataAssignedPlaceholder({
  graphic,
  callToAction = true,
  text,
  ButtonLabel,
  onClick,
  containerStyle,
  extra = <></>,
}) {
  return (
    <div className="noDataAssignedContainer" style={containerStyle}>
      {graphic}
      <h4 className="noDataAssignedText">{text}</h4>
      {callToAction && (
        <Button
          onClick={onClick}
          type="primary"
          size="large"
          style={{ color: "white" }}
        >
          {ButtonLabel}
        </Button>
      )}
      {extra}
    </div>
  );
}
