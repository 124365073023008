import React from "react";
import { Button } from "@alpaca/framework";
import { useNavigate } from "react-router-dom";

function AfterReset(props) {
  const navigate = useNavigate();
  return (
    <>
      <section className="forgotPasswordBackground">
        <div className="afterReset">
          <h2 className="medium headline">Password Recovery</h2>
          <p className="body-text">
            You will receive an email from us shortly. Be sure to check your
            junk/span folder
          </p>
          <div className="afterReset_btnContainer">
            <Button
              type="primary"
              loading={false}
              className="forgotPasswordBtn"
              onClick={() => (window.location.pathname = "/portal")}
            >
              Return to Login
            </Button>
            <Button
              type="primary"
              loading={false}
              className="afterReset_helpBtn"
              onClick={props.onBtnClick}
            >
              Still Need Help?
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default AfterReset;
