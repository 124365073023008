import "./editDevice.styles.css";
import React, { useState } from "react";
import { Input, Icon } from "@alpaca/framework";
import { NoDataAssignedPlaceholder } from "../NewDevice/NoDataAssignedPlaceholder";
import { ReactComponent as NoAppsAvailable } from "../../../../assets/graphics/NoAppsAvailable.svg";

function AppList(props) {
  const apps = props.modules;
  const [search, setSearch] = useState("");

  const handleCheckBoxChange = (index) => {
    const appArray = JSON.parse(JSON.stringify(props.device.app_used)); // Deep Copy

    let findIndex = appArray.findIndex(
      (app) => app.app_name === apps[index].app_name
    );
    if (findIndex === -1) {
      appArray.push(apps[index]);
    } else {
      appArray.splice(findIndex, 1);
    }

    props.onData({ ...props.device, app_used: appArray });
  };

  return (
    <div>
      <Input
        className="searchbar mb20"
        size="large"
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        prefix={<Icon icon="search" />}
      />
      <div className="app-list">
        {apps ? (
          apps.map((app, index) => {
            if (!app.app_name.toLowerCase().includes(search)) return <></>;
            const background = app.app_background;
            const inList = props.device.app_used?.some(
              (e) => e.app_name === app.app_name
            );

            return (
              <div
                className={`edit-device-list-item`}
                onClick={() => handleCheckBoxChange(index)}
              >
                <div className={`app-list-item ${inList && "app-selected"}`}>
                  <img
                    src={
                      background.startsWith("data")
                        ? background
                        : `data:image/jpeg;base64,${background}`
                    }
                    alt="Base64 JPG -100"
                    title=""
                    className="app-list-background"
                  />
                  <div className="app-list-title headline">
                    <br />
                    {app.app_name}
                  </div>
                  <img
                    src={`data:image/svg+xml;base64,${app?.app_icon}`}
                    alt="Base64 SVG"
                    title=""
                    className="app-list-icon"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <NoDataAssignedPlaceholder
            containerStyle={{ margin: "auto" }}
            text="Sorry, no apps could be found"
            graphic={<NoAppsAvailable style={{ width: "200px", margin: 0 }} />}
            callToAction={false}
          />
        )}
      </div>
    </div>
  );
}

export default AppList;
