import React from "react";

export function AppsAssigned({ apps = [] }) {
  return (
    <>
      {apps.map((app, index) => {
        return (
          <div key={index}>
            <div className={`edit-device-list-item`}>
              <div className={`app-list-item`}>
                <img
                  src={
                    app.app_background.startsWith("data")
                      ? app.app_background
                      : `data:image/jpeg;base64,${app.app_background}`
                  }
                  alt="Base64 JPG -100"
                  title=""
                  className="app-list-background"
                />
                <div className="app-list-title headline">
                  <br />
                  {app.app_name}
                </div>
                <img
                  src={`data:image/svg+xml;base64,${app?.app_icon}`}
                  alt="Base64 SVG"
                  title=""
                  className="app-list-icon"
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
