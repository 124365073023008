import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Table, TableHeader } from "@alpaca/framework";
import { ClassSelector } from "../../../store/reduxSelector";

import columns from "./devices.columns";
import EditDeviceModal from "./EditDeviceModal/EditDeviceModal.component";

import {
  getAllDevices,
  getDeviceCookie,
  getCurrentDevice,
  updateDevice,
} from "../../../service/devices/deviceServices";
import { getAllModules } from "../../../service/ModuleService";
import NewDeviceModal from "./NewDevice/NewDeviceModal";
import "./devicesPage.styles.css";

function DevicesPage(props) {
  const { userInfo } = props;

  const [devices, setDevices] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingDevice, setEditingDevice] = useState({});
  const [modules, setModules] = useState([]);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [newDeviceModal, setNewDeviceModal] = useState(false);
  const [deviceModalTitle, setDeviceModalTitle] = useState("");

  useEffect(() => {
    getCurrentDevice()
      .then((result) => setCurrentDevice(result))
      .catch(() => setCurrentDevice(null));

    getAllDevices(userInfo?.company_name)
      .then((result) => setDevices(result))
      .catch((error) => console.log(error));

    getAllModules()
      .then((data) => {
        setModules(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userInfo]);

  function setAsCurrentDevice({ device_id }) {
    getDeviceCookie(device_id);
    setCurrentDevice(device_id);
  }

  function editDevice(device) {
    let deviceToSet = device;
    if (!deviceToSet.profile) deviceToSet.profile = [];
    if (!deviceToSet.app_used) deviceToSet.app_used = [];
    setEditingDevice(deviceToSet);
    setShowEditModal(true);
  }

  const openNewDeviceModal = useCallback(() => {
    setNewDeviceModal(true);
    setDeviceModalTitle("Add a New Device");
  }, []);

  const closeNewDeviceModal = useCallback(() => {
    setNewDeviceModal(false);
    setDeviceModalTitle("");
  }, []);

  async function saveDevice(updatedDevice) {
    let result = await updateDevice(updatedDevice);
    return result;
  }

  useEffect(() => {
    const handleKeydown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.altKey && e.key.toLowerCase() === "n") {
        e.preventDefault();
        openNewDeviceModal();
      } else if (e.key === "Escape") {
        e.preventDefault();
        closeNewDeviceModal();
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [openNewDeviceModal]);

  return (
    <div className="devicesCollapse">
      <div className="devices-page">
        <TableHeader
          title={
            <div className="devicesPageTitleContainer">
              <h2 className="medium">Devices</h2>
              <button className="addDeviceBtn" onClick={openNewDeviceModal}>
                Add Device
              </button>
            </div>
          }
          showOptions={false}
        />
        <Table
          columns={columns({
            modules: modules,
            onEdit: editDevice,
            setAsCurrentDevice: setAsCurrentDevice,
            currentDevice: currentDevice,
            onDelete: () => {},
          })}
          dataSource={devices}
          pagination={false}
          className="settings-table"
        />
      </div>
      <EditDeviceModal
        open={showEditModal}
        device={editingDevice}
        modules={modules}
        saveDevice={saveDevice}
        onCancel={() => setShowEditModal(false)}
      />

      <NewDeviceModal
        modules={modules}
        openNewDeviceModal={newDeviceModal}
        closeNewDeviceModal={closeNewDeviceModal}
        newDeviceModalTitle={deviceModalTitle}
      />
    </div>
  );
}

const stateList = ["userInfo"];
export default connect((state) => ClassSelector(state, stateList))(DevicesPage);
