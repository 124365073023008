import "./editDevice.styles.css";
import { Link } from "react-router-dom";
import { Modal } from "@alpaca/framework";
import React, { Component } from "react";
import AppList from "./appList.component";
import UserList from "./userList.component";
import { Toast, Icon } from "@alpaca/framework";
import { addNewDevice } from "../../../../service/devices/deviceServices";
import withRouter from "../../../../helpers/withRouter";
import EditParagraph from "../../../../components/editParagraph/editParagraph.component";
import { connect } from "react-redux";

class NewDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandStatus: true,
      showAppModal: false,
      showUserModal: false,
      device: {
        device_name: "",
        appUsed: [],
        profile: [],
      },
    };
    this.toastRef = React.createRef();
  }

  saveNewDevice = async () => {
    if (this.state.device.device_name === "") {
      this.toastRef.current.open({
        title: "Empty Device Name",
        description: "Please enter new device name!",
        duration: 8,
        status: "error",
      });
      return;
    }
    try {
      await addNewDevice({
        ...this.state.device,
        company: this.props.userInfo?.company_name,
      });
      this.props.navigate("/dashboard/Devices");
    } catch (error) {
      this.toastRef.current.open({
        title: `Error Add new Device!`,
        description: error.message,
        duration: 10,
        status: "error",
      });
    }
  };

  openToast = (toastData) => {
    this.toastRef.current?.open(toastData);
  };

  render() {
    return (
      <div>
        <Toast ref={this.toastRef} />
        <section className="edit-devices-header">
          <h1 className="devices-welcome-headline">New Device</h1>
        </section>
        <div className="edit-devices-header-small">
          <p />
          <p />
          <Link to="/dashboard/Devices">
            <button className="main btn device-settings-button">
              <Icon icon="back" className="device-item-btn-icon" />
              Back
            </button>
          </Link>
        </div>
        <div className="edit-device-main">
          <div className="edit-device-text-container">
            <EditParagraph
              className="edit-device-name"
              name="Enter Name Here"
              value={this.state.device.device_name}
              onSave={(name, newValue) =>
                this.setState({
                  device: { ...this.state.device, device_name: newValue },
                })
              }
            />
          </div>
          {/* <h1>
								Calgary, AB
								<span className="log-in-text pointer">
									&nbsp;&nbsp;Not Correct?
								</span>
							</h1> */}
        </div>
        <div className="edit-device-secondary">
          <div className="edit-device-text-container">
            <p className="edit-device-text">Surface Pro 3</p>
            <div className="edit-device-spacer" />
          </div>
          <div></div>
          <div className="edit-device-text-container">
            <p className="edit-device-text">
              Locked to
              <span className="edit-device-text secondary-color ">
                &nbsp;{this.state.device.appUsed.length}&nbsp;
              </span>
              Apps
            </p>
          </div>
          <button
            className="main btn"
            onClick={() => this.setState({ showAppModal: true })}
          >
            Edit
          </button>
          <div className="edit-device-text-container">
            <p className="edit-device-text">
              Whitelisted to
              <span className="edit-device-text secondary-color ">
                &nbsp;{this.state.device.profile.length}&nbsp;
              </span>
              Users
            </p>
          </div>
          <button
            className="main btn"
            onClick={() => this.setState({ showUserModal: true })}
          >
            Edit
          </button>
        </div>
        <Modal
          open={this.state.showAppModal}
          title="Lock to App"
          width={800}
          height={800}
          onCancel={() => this.setState({ showAppModal: false })}
          footer={<button className="main btn">Save</button>}
          children={
            <AppList
              device={this.state.device}
              onData={(newDevice) => this.setState({ device: newDevice })}
            />
          }
        />
        <Modal
          open={this.state.showUserModal}
          title="User Whitelist"
          width={800}
          height={800}
          onCancel={() => this.setState({ showUserModal: false })}
          children={
            <UserList
              device={this.state.device}
              onData={(newDevice) => this.setState({ device: newDevice })}
              userInfo={this.props.userInfo}
              openToast={this.openToast}
            />
          }
        />
        <button className="main btn save-button" onClick={this.saveNewDevice}>
          Save
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});
export default connect(mapStateToProps)(withRouter(NewDevice));
