import React, { Component } from "react";
import "./modulesButton.styles.scss";
import "./modules.styles.css";
import "../../pages/dashboard/mainPage.styles.css";
import PlaceholderImg from "./../../assets/Img/Modules/placeholder.jpg";
import { Modal, Button } from "@alpaca/framework";

export default class ModuleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // activates the modal ability
      openModal: false, // determines when the modal is open
    };
  }
  closeModal = () => {
    this.setState({ openModal: false });
  };
  render() {
    return (
      <div
        onClick={() => {
          if (this.props.disabled) {
            if (this.state.showModal) {
              this.setState({ openModal: true });
            } else {
              window.location = this.props.href;
            }
          }
        }}
        className={
          "module-grid-item" +
          (this.props.disabled === 1 ? " gray-out-button" : "")
        }
      >
        <div className="module-grid-item-img-container">
          <img
            src={
              this.props.background?.startsWith("data")
                ? this.props.background
                : `data:image/jpeg;base64,${this.props.background}`
            }
            // src={`${this.props.background}`}
            alt="Base64 JPG -100"
            title=""
          />
          <div className="module-grid-item-icon-container">
            <img
              src={
                this.props.icon?.startsWith("data")
                  ? this.props.icon
                  : `data:image/svg+xml;base64,${this.props.icon}`
              }
              alt="Base64 SVG"
              title=""
            />
          </div>
        </div>

        <h2 className="module-btn-headline">{this.props.header}</h2>
        <p className="body-text module-body-text">{this.props.body}</p>
        {this.state.showModal && (
          <Modal
            open={this.state.openModal}
            maskClosable={true}
            onCancel={this.closeModal}
            title={this.props.header}
            height="70vh"
            width="60vw"
            children={
              <div>
                <img
                  src={
                    this.props.background?.startsWith("data")
                      ? this.props.background
                      : `data:image/jpeg;base64,${this.props.background}`
                  }
                  // src={`${this.props.background}`}
                  alt="Base64 JPG -100"
                  title=""
                />
                <h1 className="module-body-text">{this.props.body}</h1>
                <p className="body-text">Description</p>
              </div>
            }
            footer={
              <Button
                onClick={() => {
                  if (this.props.disabled) {
                    window.location = this.props.href;
                  }
                }}
                type="primary"
                className="white-text-fix"
              >
                Open App
              </Button>
            }
          />
        )}
      </div>
    );
  }
}

ModuleButton.defaultProps = {
  name: "Undefined",
};
