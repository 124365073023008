import React from "react";
import { Icon, ThreeDotMenu, Button } from "@alpaca/framework";

export default function EditButton({ showEditModal, showDeleteModal }) {
  return (
    <>
      <Button
        icon={<Icon icon="gear" />}
        onClick={showEditModal}
        style={{ marginRight: "10px" }}
      >
        Edit
      </Button>
      {/* 
      <Button
        danger
        icon={<Icon icon="trash" />}
        onClick={() => showDeleteModal()}
      >
        Delete
      </Button> */}

      {/* <ThreeDotMenu
          items={[
            {
              label: "Edit",
              icon: <Icon icon="gear" className="icon-btn" />,
              onSelect: () => showEditModal(),
            },
            {
              label: "Delete",
              icon: <Icon icon="trash" className="icon-btn" />,
              onSelect: () => showDeleteModal(),
            },
          ]}
        /> */}
    </>
  );
}
