import "./editDevice.styles.css";
import React from "react";

function UptimeLog() {
  return (
    <>
      <div className="edit-device-list">
        <div className="edit-device-list-item">
          <h3>Graph</h3>
        </div>
      </div>
    </>
  );
}

export default UptimeLog;
