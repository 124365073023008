import { Modal, Input, Form, Card, ModalFooterItems } from "@alpaca/framework";
import React, { useState, useRef, useEffect } from "react";
import AppList from "../EditDevice/appList.component";
import UserList from "../EditDevice/userList.component";
import { Toast } from "@alpaca/framework";
import { addNewDevice } from "../../../../service/devices/deviceServices";
import withRouter from "../../../../helpers/withRouter";
import { connect } from "react-redux";
import "./newDeviceModal.styles.scss";
import { NoDataAssignedPlaceholder } from "./NoDataAssignedPlaceholder";

import { ReactComponent as NoUsersAssigned } from "../../../../assets/graphics/NoUsersAssigned.svg";
import { ReactComponent as NoAppsAssigned } from "../../../../assets/graphics/NoAppsAssigned.svg";

import { UsersAssigned } from "../EditDevice/UsersAssigned,";
import { AppsAssigned } from "../EditDevice/AppsAssigned";

const NewDevice = ({
  navigate,
  userInfo,
  openNewDeviceModal,
  newDeviceModalTitle,
  closeNewDeviceModal,
  modules,
  handleClose,
}) => {
  const [showAppModal, setShowAppModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState({
    device_name: "",
    app_used: [],
    profile: [],
  });

  const [unsavedDevice, setUnsavedDevice] = useState(device);
  const toastRef = useRef();

  const saveNewDevice = async () => {
    setLoading(true);
    if (device.device_name === "") {
      toastRef.current.open({
        title: "Empty Device Name",
        description: "Please enter new device name!",
        duration: 8,
        status: "error",
      });
      setLoading(false);
      return;
    }
    try {
      await addNewDevice({
        ...device,
        company: userInfo?.company_name,
      });
      closeNewDeviceModal();
    } catch (error) {
      toastRef.current.open({
        title: `Error Add new Device!`,
        description: error.message,
        duration: 10,
        status: "error",
      });
    }
    setLoading(false);
  };

  const openToast = (toastData) => {
    toastRef.current?.open(toastData);
  };

  function applyChanges() {
    setDevice(unsavedDevice);
  }

  return (
    <Modal
      open={openNewDeviceModal}
      title={newDeviceModalTitle}
      onCancel={closeNewDeviceModal}
      footer={
        <ModalFooterItems
          items={[
            { label: "Cancel", onClick: closeNewDeviceModal },
            {
              label: !loading ? "Save" : "Loading", // TODO SHOULD BE LOADING ICON
              onClick: saveNewDevice,
              className: "modalFooterSaveBtn",
            },
          ]}
        />
      }
    >
      <div className="newDeviceModalContainer">
        <Toast ref={toastRef} />

        <Form>
          <Form.Item labelCol={{ span: 24 }}>
            <Input
              autoFocus={true}
              placeholder="Enter Device Name Here"
              size="large"
              onChange={(e) => {
                setUnsavedDevice({ ...device, device_name: e.target.value });
                setDevice({ ...device, device_name: e.target.value });
              }}
            />
          </Form.Item>

          <div className="usersAndAppsCardContainer">
            <Card title="Apps Assigned" className="AppListsCard">
              {device.app_used.length === 0 ? (
                <NoDataAssignedPlaceholder
                  text="No apps have been assigned"
                  graphic={
                    <NoAppsAssigned style={{ width: "150px", margin: 0 }} />
                  }
                  ButtonLabel="Assign an App"
                  onClick={() => setShowAppModal(true)}
                />
              ) : (
                <div className="app-list">
                  <AppsAssigned apps={device.app_used} />
                </div>
              )}
            </Card>
            <Card title="Users Assigned" className="usersCard">
              {device.profile.length === 0 ? (
                <NoDataAssignedPlaceholder
                  text="No users have been assigned"
                  graphic={
                    <NoUsersAssigned style={{ width: "150px", margin: 0 }} />
                  }
                  ButtonLabel="Assign a User"
                  onClick={() => setShowUserModal(true)}
                />
              ) : (
                <UsersAssigned
                  addUser={() => setShowUserModal(true)}
                  users={device.profile}
                />
              )}
            </Card>
          </div>
        </Form>

        {/* <div className="edit-device-main">
          <div className="edit-device-text-container">
            <EditParagraph
              className="edit-device-name"
              name="Enter Name Here"
              value={device.device_name}
              onSave={(name, newValue) =>
                setDevice((prev) => ({ ...prev, device_name: newValue }))
              }
            />
          </div>
        </div>
        <div className="edit-device-secondary">
          <div className="edit-device-text-container">
            <p className="edit-device-text">Surface Pro 3</p>
            <div className="edit-device-spacer" />
          </div>
          <div></div>
          <div className="edit-device-text-container">
            <p className="edit-device-text">
              Locked to
              <span className="edit-device-text secondary-color ">
                &nbsp;{device.app_used.length}&nbsp;
              </span>
              Apps
            </p>
          </div>
          <button className="main btn" onClick={() => setShowAppModal(true)}>
            Edit
          </button>
          <div className="edit-device-text-container">
            <p className="edit-device-text">
              Whitelisted to
              <span className="edit-device-text secondary-color ">
                &nbsp;{device.profile.length}&nbsp;
              </span>
              Users
            </p>
          </div>
          <button className="main btn" onClick={() => setShowUserModal(true)}>
            Edit
          </button>
        </div> */}
        <Modal
          open={showAppModal}
          title="Lock to App"
          width={800}
          height={800}
          onCancel={() => setShowAppModal(false)}
          footer={
            <ModalFooterItems
              items={[
                { label: "Cancel", onClick: () => setShowAppModal(false) },
                {
                  label: "Save",
                  onClick: () => applyChanges(),
                  className: "modalFooterSaveBtn",
                },
              ]}
            />
          }
          children={
            <AppList
              device={unsavedDevice}
              modules={modules}
              onData={(newDevice) => {
                console.log(newDevice);
                setUnsavedDevice(newDevice);
              }}
            />
          }
        />
        <Modal
          open={showUserModal}
          title="Add a User"
          width={800}
          height={800}
          onCancel={() => setShowUserModal(false)}
          footer={
            <ModalFooterItems
              items={[
                { label: "Cancel", onClick: () => setShowUserModal(false) },
                {
                  label: "Save",
                  onClick: () => applyChanges(),
                  className: "modalFooterSaveBtn",
                },
              ]}
            />
          }
          children={
            <UserList
              device={unsavedDevice}
              onData={(newDevice) => setUnsavedDevice(newDevice)}
              userInfo={userInfo}
              openToast={openToast}
            />
          }
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps)(withRouter(NewDevice));
