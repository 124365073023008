import React, { useState, useEffect } from "react";
import { Modal, ModalFooterItems } from "@alpaca/framework";
import UserList from "../EditDevice/userList.component";
import AppList from "../EditDevice/appList.component";
export default function EditDeviceModal({
  open,
  onCancel,
  modules,
  device,
  saveDevice = () => {},
  ...props
}) {
  const [unsavedDevice, setUnsavedDevice] = useState(device);

  useEffect(() => {
    setUnsavedDevice(device);
  }, [device]);

  return (
    <Modal
      {...props}
      title="Edit Device"
      open={open}
      onCancel={onCancel}
      children={
        <>
          <div style={{ display: "grid", gridTemplateColumns: "80% auto" }}>
            <AppList
              modules={modules}
              device={unsavedDevice}
              onData={(newDevice) => setUnsavedDevice(newDevice)}
            />
            <UserList
              userInfo={{ company_name: "Steelhaus Technologies Inc." }}
              device={unsavedDevice}
              onData={(newDevice) => setUnsavedDevice(newDevice)}
            />
          </div>
        </>
      }
      footer={
        <ModalFooterItems
          items={[
            { label: "Cancel", onClick: () => onCancel() },
            {
              label: "Save",
              onClick: () => saveDevice(unsavedDevice),
              className: "modalFooterSaveBtn",
            },
          ]}
        />
      }
    />
  );
}
