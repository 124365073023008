import React, { useState, useEffect } from "react";
import ModuleButton from "./modulesButton.component";
import { getAllModules } from "../../service/ModuleService";
import ModulesHeaderComponent from "./modulesHeader.component";
import "./modules.styles.css";
import "./modulesButton.styles.scss";
import "../../pages/dashboard/mainPage.styles.css";
import PermissionDenied from "./permissionDenied.component";
import SeverError from "./serverError.component";

function Modules(autoRedirect) {
  const [showDisabled, setShowDisabled] = useState(true);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    getAllModules()
      .then((data) => {
        if (autoRedirect && data.length === 1) {
          window.location.href = data[0].app_url;
        } else {
          setModules(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setModules(null);
        setErrorMessage(error.message);
        setLoading(false);
      });
  }, []);

  let pageContent;
  if (loading) {
    pageContent = null;
  } else if (!Array.isArray(modules)) {
    pageContent = <SeverError errorMessage={errorMessage} />;
  } else if (modules.length > 0) {
    const filteredModules = showDisabled
      ? modules
      : modules.filter((module) => module.disabled !== 1);

    pageContent = (
      <section className="moduleButtonList">
        {filteredModules.map((module, index) => (
          <div
            className={`module-animate module-animate-${index + 1}`}
            key={module.app_name}
          >
            <ModuleButton
              name={module.app_name}
              icon={module.app_icon}
              header={module.app_name}
              body={module.app_description}
              background={module.app_background}
              href={module.app_url}
              disabled={module.disabled}
            />
          </div>
        ))}
      </section>
    );
  } else {
    pageContent = <PermissionDenied />;
  }

  return (
    <>
      <ModulesHeaderComponent />
      <div className="header-btn-modules">
        <button
          className="main btn"
          onClick={() => setShowDisabled(!showDisabled)}
        >
          {showDisabled ? "Hide " : "Show "}
          Disabled
        </button>
      </div>
      {pageContent}
    </>
  );
}

export default Modules;
